/** @format */

.logo {
	width: 6vw !important;
	display: block;
	position: relative;
	margin-top: 5%;
	margin-bottom: -20%;
	/* margin-left: -15% !important; */
}

.results-List {
	width: 100%;
	margin-top: 20vh;
}

.css-wuvinw {
	border: 1px solid red;
	display: flex;
}

.blur {
	filter: blur(1.5px);
}

.MuiButton-root:hover {
	background-color: #c2cf85 !important;
	color: #efefef !important;
}

.job-btn {
	margin: 5px;
	border-color: #9eb387;
	color: #403b33;
}

.job-btn:hover {
	background-color: #c2cf85;
	border-color: #bac48c;
	color: #efefef;
}
.job-btn::selection {
	background-color: #c2cf85;
	border-color: #bac48c;
	color: #efefef;
}

.rbt-token {
	color: #bac48c !important;
	background: #f3f5ed;
	border: 0.2px solid #bac48c !important;
}

.rbt-input-multi.focus {
	box-shadow: 0 0 0 0.1rem #bac48c !important;
	border-color: #9eb387;
}

.logo {
	display: block;
	margin-left: -15%;
	width: 40%;
}

.bounce {
	-webkit-animation-duration: 0.8s;
	animation-duration: 0.8s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: bounce;
	animation-name: bounce;
}

@-webkit-keyframes bounce {
	0%,
	100% {
		-webkit-transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(-5px);
	}
}
@keyframes bounce {
	0% {
		transform: scale(1, 1) translateY(0);
	}

	30% {
		transform: scale(0.9, 1.1) translateY(0);
	}
	50% {
		transform: scale(1.05, 0.95) translateY(0);
	}
	64% {
		transform: scale(1, 1) translateY(0);
	}
	100% {
		transform: scale(1, 1) translateY(0);
	}
}
